import React from "react";

const MonterreyScheduleHeader = () => {

  return (
    <div className="row w-100 text-black bold schedule__header justify-content-between mx-auto ">
      <div className="col">
        <div className="day-type__container mx-auto">
          <p className="schedule__day-type">TREN INFERIOR</p>
          <p className="schedule__day">Lunes</p>
        </div>
      </div>
      <div className="col">
        <div className="day-type__container mx-auto">
          <p className="schedule__day-type">TREN SUPERIOR</p>
          <p className="schedule__day">Martes</p>
        </div>
      </div>
      <div className="col">
        <div className="day-type__container mx-auto">
          <p className="schedule__day-type">TREN INFERIOR</p>
          <p className="schedule__day">Miércoles</p>
        </div>
      </div>
      <div className="col">
        <div className="day-type__container mx-auto">
          <p className="schedule__day-type">TREN SUPERIOR</p>
          <p className="schedule__day">Jueves</p>
        </div>
      </div>
      <div className="col">
        <div className="day-type__container mx-auto">
          <p className="schedule__day-type">TREN INFERIOR</p>
          <p className="schedule__day">Viernes</p>
        </div>
      </div>
      <div className="col pe-0">
        <div className="day-type__container mx-auto">
          <p className="schedule__day-type">FULL BODY</p>
          <p className="schedule__day">Sábado</p>
        </div>
      </div>
    </div>
  );
};

export default MonterreyScheduleHeader;
