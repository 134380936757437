import React, { createContext, useContext, useReducer } from "react";
import ClassInstructorReducer from "../reducers/ClassInstructorReducer";
import ClassInstructorService from "../services/ClassInstructorService";
import ClassTypesService from "../services/ClassTypesService";
import {
  RESERVATIONS_RECIBIDAS,
  CLASS_TYPES_RECIBIDOS,
  SEMANAS_RECIBIDAS,
  CLASES_RECIBIDAS,
  SET_START_DATE,
  CREATE_CLASE,
  SET_END_DATE,
  SET_CLASE,
  SET_MONTH,
  SET_WEEK,
} from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";
import { AuthContext } from "./AuthContext";

const initialState = {
  instructorClass: null,
  selectedWeek: null,
  reservations: null,
  class_types: null,
  clases: null,
  clase: null,
  month: null,
  weeks: null,
};

export const SingleClassContext = createContext(initialState);

export const SingleClassProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ClassInstructorReducer, initialState);

  const { alert, success } = useContext(ModalContext);

  const { getUsuario } = useContext(AuthContext);

  const setMonth = (month) => {
    dispatch({ type: SET_MONTH, payload: month });
  };

  const setWeek = (week) => {
    dispatch({ type: SET_WEEK, payload: week });
  };

  const getMyReservations = () => {
    ClassInstructorService.getMyReservations().then((res) => {
      const { reservations } = res.data;
      dispatch({ type: RESERVATIONS_RECIBIDAS, payload: reservations });
    });
  };

  const getClases = (fechaInicio, fechaFin) => {
    ClassInstructorService.getClases(fechaInicio, fechaFin).then((res) => {
      const { clases } = res.data;
      dispatch({ type: CLASES_RECIBIDAS, payload: clases });
    });
  };

  const getClassTypes = () => {
    ClassTypesService.getClassTypes().then((res) => {
      const { class_types } = res.data;
      dispatch({ type: CLASS_TYPES_RECIBIDOS, payload: class_types });
    });
  };

  const getSchedule = (params) => {
    ClassInstructorService.getWeeks(params).then((res) => {
      const { classes } = res.data;
      dispatch({ type: SEMANAS_RECIBIDAS, payload: classes });
    });
  };

  const postReservacion = (clase) => {
    ClassInstructorService.postReservacion(clase)
      .then(() => {
        hideModal();
        getMyReservations();
        getUsuario();
        success("¡Reservación realizada con éxito!");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            return alert(
              "Lo sentimos, ya no tienes créditos vigentes para clases presenciales."
            );
          } else if (error.response.status === 409) {
            return alert("Lo sentimos, la clase se ha llenado.");
          }
        }
      });
  };

  const cancelReservacion = (class_reservation_id) => {
    ClassInstructorService.cancelReservacion(class_reservation_id)
      .then(() => {
        hideModal();
        getUsuario();
        getMyReservations();
        success("¡Reservación cancelada con éxito!");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            hideModal();
            alert(
              "Lo siento, para cancelar tu clase necesitas al menos 1 hora de anticipación."
            );
          }
        }
      });
  };

  const getClase = (class_instructor_id) => {
    ClassInstructorService.getClase(class_instructor_id).then((res) => {
      const { clase } = res.data;
      dispatch({ type: SET_CLASE, payload: clase });
    });
  };

  const createClase = () => {
    dispatch({ type: CREATE_CLASE });
  };

  const setClase = (clase) => {
    dispatch({ type: SET_CLASE, payload: clase });
  };

  const clearClase = () => {
    dispatch({ type: SET_CLASE, payload: null });
  };

  const clearSemanas = () => {
    dispatch({ type: SEMANAS_RECIBIDAS, payload: null });
  }

  const setStartDate = (start_date) => {
    dispatch({ type: SET_START_DATE, payload: start_date });
  };

  const setEndDate = (end_date) => {
    dispatch({ type: SET_END_DATE, payload: end_date });
  };

  return (
    <SingleClassContext.Provider
      value={{
        ...state,
        setClase,
        getClase,
        setMonth,
        setWeek,
        getClases,
        clearClase,
        getSchedule,
        createClase,
        setEndDate,
        setStartDate,
        getClassTypes,
        clearSemanas,
        postReservacion,
        cancelReservacion,
        getMyReservations,
      }}
    >
      {children}
    </SingleClassContext.Provider>
  );
};
