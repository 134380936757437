import React, { useContext, useEffect, useState } from "react";
import { PaymentSourcesContext } from "../../context/PaymentSourcesContext";
import PaymentMethodCard from "../payment_sources/PaymentMethodCard";
import { CheckoutContext } from "../../context/CheckoutContext";
import PaymentSource from "../payment_sources/PaymentSource";
import CheckoutService from "../../services/CheckoutService";
import { handleCheckoutError } from "../../utils/checkout";
import { ModalContext } from "../../context/ModalContext";
import { CartContext } from "../../context/CartContext";
import { AuthContext } from "../../context/AuthContext";
import StripeCheckout from "../common/StripeCheckout";
import SubmitButton from "../common/SubmitButton";
import useBranch from "../../hooks/useBranch";
import { navigate } from "@reach/router";

const CheckoutPaymentMethods = ({
  metadata,
  class_package_id,
  installmentsOptions,
}) => {
  const [installments, setInstallments] = useState(1);
  const [processing, setProcessing] = useState(false);

  const { branch } = useBranch();
  const { user } = useContext(AuthContext);
  const { alert } = useContext(ModalContext);
  const { payment_sources, getPaymentSources } = useContext(
    PaymentSourcesContext
  );

  const {
    descuento,
    setPayPal,
    discountCode,
    class_package,
    payment_source,
    setPaymentSource,
    setPayPalSubscription,
  } = useContext(CheckoutContext);
  const { cart } = useContext(CartContext);

  useEffect(() => {
    if (user !== null) {
      getPaymentSources();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const paypalButton = document.getElementById("paypal-button");
    if (
      user !== null &&
      class_package !== null &&
      paypalButton !== null &&
      payment_source === "paypal"
    ) {
      if (class_package.is_subscription) {
        const payload = {
          cart,
          branch,
          metadata,
          class_package_id,
          discountCode,
        };
        if (
          class_package.branch_id !== branch.branch_id &&
          class_package.branch !== null
        ) {
          payload.branch = class_package.branch;
        }
        setPayPalSubscription(payload);
      } else {
        setPayPal(class_package_id, discountCode, cart, metadata);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, descuento, metadata, class_package, payment_source]);

  const renderPaymentSources = () => {
    if (payment_sources && payment_sources !== null) {
      return payment_sources
        .filter((metodo) => metodo.source_id !== null)
        .map((metodo) => (
          <PaymentSource
            payment_source={metodo}
            paymentMethod={payment_source}
            key={metodo.payment_source_id}
            setPaymentSource={setPaymentSource}
            selectedInstallments={installments}
            installmentsOptions={installmentsOptions}
            setSelectedInstallments={setInstallments}
          />
        ));
    }
  };

  const renderPayPal = () => {
    return (
      <PaymentMethodCard
        name="paypal"
        label="PayPal"
        selected={payment_source}
        setPaymentMethod={setPaymentSource}
      >
        <div
          id="paypal-button"
          style={{
            visibility: payment_source === "paypal" ? "visible" : "hidden",
          }}
        ></div>
      </PaymentMethodCard>
    );
  };

  const handleError = (message) => {
    setProcessing(false);
    alert(message);
  };

  const handleSuccess = (purchase_id) => {
    setProcessing(false);
    navigate(`/gracias/${purchase_id}`);
  };

  const handleSubmit = () => {
    setProcessing(true);
    CheckoutService.postCheckout(
      class_package_id,
      discountCode,
      payment_source,
      cart,
      metadata
    )
      .then(async (res) => {
        const { purchase_id } = res.data;
        handleSuccess(purchase_id);
      })
      .catch((error) => handleCheckoutError(error, handleError));
  };

  const renderPayButton = () => {
    if (!["card", "paypal"].includes(payment_source)) {
      return (
        <div className="container-fluid px-0 text-right">
          <SubmitButton
            label="Pagar Ahora"
            spinner={processing}
            onClick={handleSubmit}
          />
        </div>
      );
    }
  };

  const renderPago = () => {
    if (user !== null) {
      if (class_package && class_package !== null) {
        if (parseFloat(class_package.price) === 0.0) {
          return (
            <div className="container-fluid px-0 text-right">
              <SubmitButton
                label="Obtener Ahora"
                spinner={processing}
                onClick={handleSubmit}
              />
            </div>
          );
        }
      }
      return (
        <div className="oveflow-hidden">
          <h3>Forma de Pago</h3>
          {renderPaymentSources()}
          <PaymentMethodCard
            name="card"
            label="Tarjeta de Crédito/Débito"
            selected={payment_source}
            setPaymentMethod={setPaymentSource}
          >
            {payment_source === "card" && (
              <StripeCheckout
                metadata={metadata}
                discountCode={discountCode}
                element_id={class_package_id}
                installmentsOptions={installmentsOptions}
              />
            )}
          </PaymentMethodCard>
          {renderPayPal()}
          {renderPayButton()}
        </div>
      );
    }
  };

  return <div>{renderPago()}</div>;
};

export default CheckoutPaymentMethods;
