import moment from "moment";
import { useState } from "react";

const weekDays = {
  Mo: [],
  Tu: [],
  We: [],
  Th: [],
  Fr: [],
  Sa: [],
};

export const useClassesByHour = () => {
  const [morningClasses, setMorningClasses] = useState([]);
  const [eveningClasses, setEveningClasses] = useState([]);
  const [startsAm, setStartsAm] = useState([]);

  const formatClasses = (classes) => {
    const allClasses = [];

    classes?.map((day) => {
      day?.details?.map((currentClass) => {
        allClasses.push(currentClass);
      });
    });

    const classRows = getClassRows(allClasses);
    const sortedRows = sortRows(classRows);

    Object.keys(sortedRows.morning).length > 0
      ? setStartsAm(true)
      : setStartsAm(false);

    setMorningClasses(sortedRows.morning);
    setEveningClasses(sortedRows.evening);
  };

  const getClassRows = (allClasses) => {
    const classRows = {
      morning: {},
      evening: {},
    };

    allClasses.map((currentClass) => {
      const classDate = moment(currentClass.class_date).utc();
      const classHour = classDate.clone().format("hh:mm");
      const classDay = classDate.clone().format("dd");
      const daySection = classDate.hours() < 12 ? "morning" : "evening";

      if (!classRows[daySection][classHour]) {
        classRows[daySection][classHour] = weekDays;
      }

      classRows[daySection][classHour] = {
        ...classRows[daySection][classHour],
        [classDay]: [
          ...(classRows[daySection][classHour][classDay] ? classRows[daySection][classHour][classDay] : []),
          currentClass,
        ],
      };
    });

    return classRows;
  };

  const sortRows = (rows) => {
    const sortedRows = {
      morning: {},
      evening: {}
    }

    const morningHours = Object.keys(rows.morning).sort((hourA, hourB) => {
      const hourAToNumber = Number(hourA.slice(0,2));
      const hourBToNumber = Number(hourB.slice(0,2));
      return hourAToNumber - hourBToNumber;
    });

    const eveningHours = Object.keys(rows.evening).sort((hourA, hourB) => {
      const hourAToNumber = Number(hourA.slice(0,2));
      const hourBToNumber = Number(hourB.slice(0,2));
      return hourAToNumber - hourBToNumber;
    });

    morningHours.map(hour => {
      sortedRows.morning[hour] = rows.morning[hour];
    });

    eveningHours.map(hour => {
      sortedRows.evening[hour] = rows.evening[hour];
    });

    return sortedRows;    
  }

  return {
    formatClasses,
    morningClasses,
    eveningClasses,
    startsAm
  }
}